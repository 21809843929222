@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_appearance.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_breakpoint.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_browser.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_calc.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_filter.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_font.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_hack.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_icon.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_keyframe.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_layout.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_letter-spacing.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_line-height.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_mixins.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_percent.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/mixin/_text.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/variable/_breakpoint.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/variable/_color.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/variable/_font.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/variable/_margin.scss";
@import "/Users/nkj/Dropbox/Sites/ninesigma/dev/cojp/_scss/variable/_width.scss";

$path: '../img/home/';
$easing: cubic-bezier(0.28, 0.11, 0.32, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
/*
 * Montserrat Regular: 400, Medium: 500, Bold: 700
 *
 * Layout
 * $block90: 1296px; / .cp-block-90 --- 1296px / 1440px
 * $block80: 1152px; / .cp-block-80 --- 1152px / 1440px
 * $block70: 1008px; / .cp-block-70 --- 1008px / 1440px
 * $block60:  864px; / .cp-block-60 ---  864px / 1440px
 */

/****************************************************
 * STYLE
 */
// .solution {

// 	.link-btn {
// 		width: 360px;
// 		@include per( $property: 'padding-top', $val: calcPer( $block90, 34, '%') );
// 		margin: 0 auto;

// 		@include mx-mq-max($bp-sp-max) {
// 			@include per( $property: 'padding-top', $val: calcPer( $block90sp, 42, '%') );
// 		}
// 	}
// }

// .service {

// 	.link-btn {
// 		width: 360px;
// 		margin: 0 auto;
// 	}
// }

.mv {
	@include calc( height, '100vh - 84px' );
	@include calc( min-height, '640px - 144px' );
	max-height: 45vw;
	position: relative;
	overflow: hidden;

	@include mx-mq-max($bp-sp-max) {
		// @include calc( height, '100vh - 60px' );
		@include per( $property: 'height', $val: calcPer( 750, 900, 'vw') );
		@include calc( max-height, '100vh - 60px' );
		min-height: inherit;
	}

	&:after {
		display: none;
	}

	.loader {
		background-color: #222;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 500;

		img {
			width: 5%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);

			@include mx-mq-max($bp-sp-max) {
				width: 20%;
			}
		}
	}

	&__opening {
		// @include calc( height, '100vh - 84px' );
		height: 100%;
		position: relative;
		z-index: 20;

		@include mx-mq-max($bp-sp-max) {
			background: url(../img/home/bg_mv.png) no-repeat 50% 50%;
			background-size: cover;
			// height: 100%;
			// min-height: inherit;
		}

		.text {
			color: #222;
			position: static;
			transform: none;
		}

		.scene {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10;

			&__block {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				text-align: center;
				font-weight: bold;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}

			[class*="lv"] {
				margin: 0;
				letter-spacing: normal;
			}

			.lv3 {
				@include per( $property: 'margin-bottom', $val: calcPerTypo( 36, 60, 'em') );
			}

			p {
				margin: 0;
			}

			span {
				display: block;
			}

			&__text {
				position: relative;

				@include mx-mq-max($bp-sp) {
					width: 100%;
				}

				&__block {

					@include mx-mq-max($bp-sp) {
						width: 100%;
					}

					&:nth-child( 2 ) {
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}

			.text {
				@include letter( -10 );
				@include fs( 48 );
				white-space: nowrap;

				@include mx-mq-max($bp-sp-max) {
					@include fs( 20 );
					white-space: inherit;
				}
			}

			[class*="-shuffle"], .icon {
				opacity: 0;
			}

			.icon {
				transform: scale(.01);
			}

			[class^="icon-"] {
				@include per( $property: 'margin-top', $val: calcPerTypo( 40, 36, 'em') );
				@include fs( 40 );

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'margin-top', $val: calcPerTypo( 36, 18, 'em') );
					@include fs( 36 );
				}

				&:before {
					color: #222;
				}
			}

			&--01 {

				.scene__block--before {

					.text {
						color: hsla(195, 93%, 57%, 1);
					}
				}

				.text {

					span {
						min-height: 4.5rem;

						@include mx-mq-max($bp-sp) {
							min-height: 2rem;
						}
					}
				}
			}

			&--03, &--05 {

				.text {
					line-height: 1;

					span {

						&:nth-child( 1 ) {
							@include fs( 36 );

							// @include mx-mq-max($bp-sp) {
							// 	@include fs( 36 );
							// }
						}

						&:nth-child( 2 ) {
							@include fs( 120 );

							@include mx-mq-max($bp-sp) {
								@include per( $property: 'margin', $val: calcPerTypo( 64, 6, 'em') 0 );
								@include fs( 64 );
							}
						}

						&:nth-child( 3 ) {
							@include fs( 36 );

							@include mx-mq-max($bp-sp) {
								@include fs( 18 );
							}
						}
					}
				}
			}
		}
	}

	&__video {
		display: block;
		width: 100vw;
		min-width: 177.77vh; /* 最小は縦幅に動画のアスペクト比をかける 9/16の数字 */
		@include calc( height, '100vh - 84px' );
		min-height: 56.25vw; /* 最小は横幅に動画のアスペクト比をかける 16/9の数字 */
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		@include mx-mq-max($bp-sp-max) {
			display: none;
		}
	}

	&__slide {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;

		&:after {
			display: block;
			background-color: rgba(69,69,69,.25);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			content: '';
		}
	}

	&__list {
		height: 100%;
		position: relative;
		overflow: hidden;

		@include mx-mq-max($bp-sp-max) {
		}

		&:before, &:after {
			display: block;
			background-color: #222;
			// opacity: .7;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			z-index: 10;
			content: '';
		}

		&:before {
			transform: translate3d( 0%, 0, 0 );
			transition: transform 1s ease 0s;

			.slick-active & {
				transform: translate3d( -100%, 0, 0 );
			}
		}

		&:after {
			transform: translate3d( 100%, 0, 0 );
			transition: transform 1s ease 3s;

			.slick-active & {
				transform: translate3d( 0%, 0, 0 );
			}

			.is-first & {
				display: none;
			}
		}

		img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			transition: transform 3s ease 0s;

			.slick-active & {
				transform: scale(1.05);
			}

			@include mx-mq-max($bp-sp-max) {
			}
		}
	}

	.slick {

		&-list, &-track, &-slide {
			height: 100%;

			@include mx-mq-max($bp-sp-max) {
			}
		}

		&-slide {

			@include mx-mq-max($bp-sp-max) {
			}

			> div {
				height: 100%;
				position: relative;
				overflow: hidden;

				@include mx-mq-max($bp-sp-max) {
				}

				&:after {
					display: block;
					background-color: #222;
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					z-index: 10;
					content: '';
					transform: translate3d( 100%, 0, 0 );
					transition: transform 1s ease 5s;
				}
			}

			&.slick-active {

				.is-first:after {
					transform: translate3d( 0%, 0, 0 );
				}
			}
		}
	}

	&__inner {
		height: 100%;

		@include mx-mq-max($bp-sp-max) {
		}
	// 	transform: translate3d( 50%, 0, 0 );
	// 	transition: transform 1s ease 1.5s;

	// 	.slick-active & {
	// 		transform: translate3d( 0%, 0, 0 );
	// 		transition-delay: 0s;
	// 	}
	}

	&__text {
		width: 100%;
		@include per( $property: 'padding-left', $val: calcPer( 1440, 145, '%') );
		// color: #002b5b;
		color: #fff;
		position: absolute;
		top: 50%;
		left: 0;
		z-index: 100;
		transform: translateY(-50%);

		@include mx-mq-max($bp-sp-max) {
			@include per( $property: 'padding', $val: 0 calcPer( $block100sp, 60, '%') );
		}

		.title {
			@include per( $property: 'margin', $val: 0 0 calcPer( 1295, 18, '%') );
			@include lh( 60, 48 );

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-bottom', $val: calcPer( 630, 24, '%') );
				@include fs( 30 );
				@include lh( 37.5, 30 );
			}
		}

		.description {
			@include per( $property: 'margin', $val: 0 0 calcPer( 1295, 30, '%') );
			@include ff( noto );
			@include lh( 28, 16 );
			@include letter( 50 );
			font-weight: bold;

			@include mx-mq-max($bp-sp-max) {
				@include per( $property: 'margin-bottom', $val: calcPer( 630, 48, '%') );
				@include fs( 12 );
				@include lh( 18, 12 );
			}
		}

		.btn {
			width: 180px;

			@include mx-mq-max($bp-sp-max) {
				width: 120px;
			}

			.tanim__outer {
				display: block;
			}

			a {
				display: block;
				@include per( $property: 'padding', $val: calcPerTypo( 16, 11, 'em') 0 );
				// @include lh( 24, 16 );
				text-align: center;
				font-weight: bold;
				border: 1px solid currentColor;
				transition: all .3s $easing;

				@include mx-mq-max($bp-sp-max) {
					@include per( $property: 'padding', $val: calcPerTypo( 12, 8, 'em') 0 );
					@include fs( 12 );
				}

				&:hover {
					color: $c-thm-01;
					// border-color: $c-thm-01;
				}
			}
		}

		.tanim {

			&__outer {
				display: inline-block;
				position: relative;
				overflow: hidden;
			}

			&__t {
				opacity: 0;
			}

			&__c {
				background-color: #222;
				width: 0;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
			}
		}
	}
}


// .cp-contact {
// 	padding-top: 0;

// 	&:after {
// 		display: none;
// 	}
// }