@charset "UTF-8";
.mv__text .description {
  font-family: Montserrat, "notosans-font", sans-serif; }
  .en .mv__text .description, .mv__text .en .description {
    font-family: "notosans-font", sans-serif; }

/*
 * Montserrat Regular: 400, Medium: 500, Bold: 700
 *
 * Layout
 * $block90: 1296px; / .cp-block-90 --- 1296px / 1440px
 * $block80: 1152px; / .cp-block-80 --- 1152px / 1440px
 * $block70: 1008px; / .cp-block-70 --- 1008px / 1440px
 * $block60:  864px; / .cp-block-60 ---  864px / 1440px
 */
/****************************************************
 * STYLE
 */
.mv {
  height: -moz-calc(100vh - 84px);
  height: -webkit-calc(100vh - 84px);
  height: calc(100vh - 84px);
  min-height: -moz-calc(640px - 144px);
  min-height: -webkit-calc(640px - 144px);
  min-height: calc(640px - 144px);
  max-height: 45vw;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .mv {
      height: 120vw;
      max-height: -moz-calc(100vh - 60px);
      max-height: -webkit-calc(100vh - 60px);
      max-height: calc(100vh - 60px);
      min-height: inherit; } }
  .mv:after {
    display: none; }
  .mv .loader {
    background-color: #222;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 500; }
    .mv .loader img {
      width: 5%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      @media screen and (max-width: 767px) {
        .mv .loader img {
          width: 20%; } }
  .mv__opening {
    height: 100%;
    position: relative;
    z-index: 20; }
    @media screen and (max-width: 767px) {
      .mv__opening {
        background: url(../img/home/bg_mv.png) no-repeat 50% 50%;
        background-size: cover; } }
    .mv__opening .text {
      color: #222;
      position: static;
      transform: none; }
    .mv__opening .scene {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10; }
      .mv__opening .scene__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: bold;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }
      .mv__opening .scene [class*="lv"] {
        margin: 0;
        letter-spacing: normal; }
      .mv__opening .scene .lv3 {
        margin-bottom: 1.66667em; }
      .mv__opening .scene p {
        margin: 0; }
      .mv__opening .scene span {
        display: block; }
      .mv__opening .scene__text {
        position: relative; }
        @media screen and (max-width: 767px) {
          .mv__opening .scene__text {
            width: 100%; } }
        @media screen and (max-width: 767px) {
          .mv__opening .scene__text__block {
            width: 100%; } }
        .mv__opening .scene__text__block:nth-child(2) {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%); }
      .mv__opening .scene .text {
        letter-spacing: -0.01em;
        font-size: 3rem;
        white-space: nowrap; }
        @media screen and (max-width: 767px) {
          .mv__opening .scene .text {
            font-size: 1.25rem;
            white-space: inherit; } }
      .mv__opening .scene [class*="-shuffle"], .mv__opening .scene .icon {
        opacity: 0; }
      .mv__opening .scene .icon {
        transform: scale(0.01); }
      .mv__opening .scene [class^="icon-"] {
        margin-top: 0.9em;
        font-size: 2.5rem; }
        @media screen and (max-width: 767px) {
          .mv__opening .scene [class^="icon-"] {
            margin-top: 0.5em;
            font-size: 2.25rem; } }
        .mv__opening .scene [class^="icon-"]:before {
          color: #222; }
      .mv__opening .scene--01 .scene__block--before .text {
        color: #2bc4f7; }
      .mv__opening .scene--01 .text span {
        min-height: 4.5rem; }
        @media screen and (max-width: 767px) {
          .mv__opening .scene--01 .text span {
            min-height: 2rem; } }
      .mv__opening .scene--03 .text, .mv__opening .scene--05 .text {
        line-height: 1; }
        .mv__opening .scene--03 .text span:nth-child(1), .mv__opening .scene--05 .text span:nth-child(1) {
          font-size: 2.25rem; }
        .mv__opening .scene--03 .text span:nth-child(2), .mv__opening .scene--05 .text span:nth-child(2) {
          font-size: 7.5rem; }
          @media screen and (max-width: 767px) {
            .mv__opening .scene--03 .text span:nth-child(2), .mv__opening .scene--05 .text span:nth-child(2) {
              margin: 0.09375em 0;
              font-size: 4rem; } }
        .mv__opening .scene--03 .text span:nth-child(3), .mv__opening .scene--05 .text span:nth-child(3) {
          font-size: 2.25rem; }
          @media screen and (max-width: 767px) {
            .mv__opening .scene--03 .text span:nth-child(3), .mv__opening .scene--05 .text span:nth-child(3) {
              font-size: 1.125rem; } }
  .mv__video {
    display: block;
    width: 100vw;
    min-width: 177.77vh;
    /* 最小は縦幅に動画のアスペクト比をかける 9/16の数字 */
    height: -moz-calc(100vh - 84px);
    height: -webkit-calc(100vh - 84px);
    height: calc(100vh - 84px);
    min-height: 56.25vw;
    /* 最小は横幅に動画のアスペクト比をかける 16/9の数字 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 767px) {
      .mv__video {
        display: none; } }
  .mv__slide {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10; }
    .mv__slide:after {
      display: block;
      background-color: rgba(69, 69, 69, 0.25);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: ''; }
  .mv__list {
    height: 100%;
    position: relative;
    overflow: hidden; }
    .mv__list:before, .mv__list:after {
      display: block;
      background-color: #222;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 10;
      content: ''; }
    .mv__list:before {
      transform: translate3d(0%, 0, 0);
      transition: transform 1s ease 0s; }
      .slick-active .mv__list:before {
        transform: translate3d(-100%, 0, 0); }
    .mv__list:after {
      transform: translate3d(100%, 0, 0);
      transition: transform 1s ease 3s; }
      .slick-active .mv__list:after {
        transform: translate3d(0%, 0, 0); }
      .is-first .mv__list:after {
        display: none; }
    .mv__list img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: transform 3s ease 0s; }
      .slick-active .mv__list img {
        transform: scale(1.05); }
  .mv .slick-list, .mv .slick-track, .mv .slick-slide {
    height: 100%; }
  .mv .slick-slide > div {
    height: 100%;
    position: relative;
    overflow: hidden; }
    .mv .slick-slide > div:after {
      display: block;
      background-color: #222;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 10;
      content: '';
      transform: translate3d(100%, 0, 0);
      transition: transform 1s ease 5s; }
  .mv .slick-slide.slick-active .is-first:after {
    transform: translate3d(0%, 0, 0); }
  .mv__inner {
    height: 100%; }
  .mv__text {
    width: 100%;
    padding-left: 10.06944%;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 100;
    transform: translateY(-50%); }
    @media screen and (max-width: 767px) {
      .mv__text {
        padding: 0 8%; } }
    .mv__text .title {
      margin: 0 0 1.38996%;
      line-height: 1.25; }
      @media screen and (max-width: 767px) {
        .mv__text .title {
          margin-bottom: 3.80952%;
          font-size: 1.875rem;
          line-height: 1.25; } }
    .mv__text .description {
      margin: 0 0 2.3166%;
      line-height: 1.75;
      letter-spacing: 0.05em;
      font-weight: bold; }
      @media screen and (max-width: 767px) {
        .mv__text .description {
          margin-bottom: 7.61905%;
          font-size: 0.75rem;
          line-height: 1.5; } }
    .mv__text .btn {
      width: 180px; }
      @media screen and (max-width: 767px) {
        .mv__text .btn {
          width: 120px; } }
      .mv__text .btn .tanim__outer {
        display: block; }
      .mv__text .btn a {
        display: block;
        padding: 0.6875em 0;
        text-align: center;
        font-weight: bold;
        border: 1px solid currentColor;
        transition: all 0.3s cubic-bezier(0.28, 0.11, 0.32, 1); }
        @media screen and (max-width: 767px) {
          .mv__text .btn a {
            padding: 0.66667em 0;
            font-size: 0.75rem; } }
        .mv__text .btn a:hover {
          color: #0b78b1; }
    .mv__text .tanim__outer {
      display: inline-block;
      position: relative;
      overflow: hidden; }
    .mv__text .tanim__t {
      opacity: 0; }
    .mv__text .tanim__c {
      background-color: #222;
      width: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0; }
